import { faXTwitter, faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Footer = ({ }) => {
  return (
    <div className="relative pt-16 md:pt-24">

      <footer className="bg-black text-white">
        <div className="container mx-auto px-4 md:px-6 py-4 flex flex-wrap justify-between items-center">
          <div>
            <p className="text-gray-400 text-sm">&copy; {new Date().getFullYear()} InstaCast.</p>
          </div>
          <div className="flex items-center space-x-6">
            <a href="https://medium.com/@ahmedbehairy/can-ai-make-us-all-podcasters-0f274ecfb627" className="text-gray-400 hover:text-gray-200">Made with love in Dubai ❤️</a>
            {/* <a href="#" className="text-gray-400 hover:text-gray-200">Privacy Policy</a>
              <a href="#" className="text-gray-400 hover:text-gray-200">Terms of Service</a> */}
          </div>
          <div className="flex items-center space-x-4">
            <a target="_blank" href="https://x.com/_ahmedbehairy" aria-label="Twitter" className="text-gray-400 hover:text-gray-200">
              <FontAwesomeIcon icon={faXTwitter} size="lg" />
            </a>
            {/* <a href="https://facebook.com" aria-label="Facebook" className="text-gray-400 hover:text-gray-200">
              <FontAwesomeIcon icon={faFacebookF} size="lg" />
            </a>
            <a href="https://instagram.com" aria-label="Instagram" className="text-gray-400 hover:text-gray-200">
              <FontAwesomeIcon icon={faInstagram} size="lg" />
            </a> */}
          </div>
        </div>
      </footer>

    </div>
  )
}
export default Footer