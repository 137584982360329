import React, { useState } from 'react';

const EditableImage = ({
  src,
  alt = "Editable Image",
  className = '',
  onImageChange,
  editable = false, // Use this prop to control edit mode
}) => {
  const [imageSrc, setImageSrc] = useState(src);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const newSrc = URL.createObjectURL(file);
      setImageSrc(newSrc);
      if (typeof onImageChange === 'function') {
        onImageChange(newSrc);
      }
    }
  };

  return (
    <div className={`relative ${className}`}>
      <img src={imageSrc} alt={alt} className="w-full h-full object-cover" />
      {editable && (
        <>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            style={{ display: 'none' }}
            id="file-upload"
          />
          <label htmlFor="file-upload" className="absolute top-0 left-0 w-full h-full cursor-pointer flex justify-center items-center">
            <div className="absolute top-0 left-0 w-full h-full bg-black opacity-0 hover:opacity-50 transition-opacity duration-300 ease-in-out"></div>
            {/* Placeholder for Edit Icon or Text */}
            <span className="z-10 text-white text-lg">Edit Image</span>
          </label>
        </>
      )}
    </div>
  );
};

export default EditableImage;
