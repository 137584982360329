import React, { useState } from 'react';
import { useApi } from '../callApi'

import EditableText from './EditableText';
import EditableImage from './EditableImage';
import { Radio } from 'react-loader-spinner';
import VoicePlayer from "../components/VoicePlayer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophoneAlt, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import PodcastModal from './PodcastModal';
// import mixpanel from 'mixpanel-browser';

function PodcastCard({ podcast, episodeID, viewMode, didInteract }) {
  const [editMode, setEditMode] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [currentPodcast, setCurrentPodcast] = useState({
    ...podcast
  });
  const [podcastGenerated, setPodcastGenerated] = useState(false); // Indicates if the podcast has been generated
  const { callApi } = useApi(true);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isModalOpen, setIsModalOpen] = useState()
  const [episode, setEpisode] = useState()

  // Handle image changes
  const handleImageChange = (newImageSrc) => {
    setCurrentPodcast({ ...currentPodcast, icon: newImageSrc });
  };

  // Handle text changes
  const handleTextChange = (newValue) => {
    setCurrentPodcast({ ...currentPodcast, name: newValue });
  };

  // Toggle edit/save mode and save changes
  const toggleEditSave = () => {
    if (editMode) {
      console.log('Saving podcast:', currentPodcast);
      setIsDataLoading(true);
      // Simulate saving the updated podcast info
      setTimeout(() => {
        setIsDataLoading(false);
        setEditMode(false);
      }, 1000);
    } else {
      setEditMode(true);
    }
  };


  const onGeneratePodcast = () => {
    if (!podcastGenerated) {
      setIsModalOpen(true)
      return
    } else
      generatePodcast(episode)
  }


  const generatePodcast = (ep) => {

    if (ep)
      setEpisode(ep)


    console.log('Generating podcast');
    // mixpanel.track('saved_episode', {
    //   environment: 'production',
    //   ...ep
    // });

    setIsDataLoading(true);

    const response = callApi("/podcast/save", { method: 'POST' },
      {
        podcast_id: podcast.id,
        episode_id: episodeID,
        ...ep
      });
    response.then(e => {
      setIsDataLoading(false);
      setPodcastGenerated(true); // The podcast is now considered generated


    }).catch(e => {
      alert(JSON.stringify(e))
    })

  }


  const sharePodcast = async () => {
    let url = apiUrl.replace('api', '') + 'preview/innerview/' + episodeID

    let title = 'Check my podcast with an AI Host on instacast.live !'
    if (navigator.share) {
      // Web Share API is available
      try {
        await navigator.share({
          title: title,
          // text: title,
          url: url
        });
        console.log('Content shared successfully');
      } catch (error) {
        console.error('Error sharing content', error);
      }
    } else {
      // Fallback, open a new window with a social media share URL
      const shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title)}`;
      window.open(shareUrl, '_blank', 'left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0');
    }
  };

  return (
    <div className="flex flex-col items-center w-full">
      <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md">
        <EditableImage
          src={currentPodcast?.photo || currentPodcast?.icon}
          className="rounded-lg w-full h-64 object-cover"
          alt="Podcast Artwork"
          onImageChange={handleImageChange}
          editable={editMode}
        />

        {
          currentPodcast.user && (
            <div className="mt-4">
              <h3 className="text-l font-semibold">{currentPodcast?.user} | {currentPodcast?.name} </h3>

              <p className="text-sm text-gray-600">
                <span className="font-semibold">  {currentPodcast?.title}
                </span>
              </p>
              {/* 
                        <p className="text-sm text-gray-600"><span className="font-semibold">  {episode?.title}      </span>
                          &nbsp; Hosted by {podcast?.hostName}</p> */}
            </div>
          )
        }

        {
          !currentPodcast.user && (
            <div className="mt-4">
              <EditableText
                onSave={handleTextChange}
                tag="h3"
                placeholder="Put your title here"
                className="text-xl font-semibold"
                initialValue={currentPodcast.name}
                editable={editMode}
              />
              <p className="text-sm text-gray-600">Hosted by

                <EditableText
                  onSave={handleTextChange}
                  tag="h3"
                  placeholder="Choose Host Name"
                  className="text-sm text-gray-600 font-semibold"
                  initialValue={currentPodcast.hostName}
                  editable={editMode}
                />

              </p>
            </div>
          )
        }

        {podcastGenerated || viewMode && <VoicePlayer audioUrl={apiUrl + '/podcast/file?session_id=' + episodeID + '&uuid=final_compilation'} />}


        <div className="mt-4 flex space-x-2 justify-center">
          {podcastGenerated && <button onClick={sharePodcast} className={`py-2 px-4 text-sm text-white bg-black rounded-lg transition duration-300 font-medium ${isDataLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-700'}`} disabled={isDataLoading}>
            <FontAwesomeIcon icon={faShareAlt} />

            Share Podcast

          </button>}

          {didInteract &&
            <button onClick={onGeneratePodcast} className={`py-2 px-4 text-sm text-white bg-black rounded-lg transition duration-300 font-medium ${isDataLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-700'}`} disabled={isDataLoading}>
              {isDataLoading ? (
                <Radio visible={true} colors={['#ffff', '#ffff', '#ffff']} width="50" height="50" wrapperStyle={{ display: 'inline-block' }} />
              ) : (

                'Save Episode'
              )}
            </button>}

          {podcastGenerated &&
            <a href={`${apiUrl}/podcast/file?session_id=${episodeID}&uuid=final_compilation`} download="podcast.mp3" className="py-2 px-4 text-sm text-white bg-blue-500 rounded-lg transition duration-300 font-medium hover:bg-blue-600">
              Download MP3
            </a>}
        </div>
        {/* <div className="mt-4 space-y-2">
          {editMode ? (
            <button
              onClick={toggleEditSave}
              className={`py-2 px-4 text-sm text-white bg-green-500 rounded-lg transition duration-300 font-medium ${isDataLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-green-700'}`}
              disabled={isDataLoading}
            >
              Save Podcast
            </button>
          ) : (
            <>
              <button
                onClick={toggleEditSave}
                className="py-2 px-4 text-sm text-white bg-black rounded-lg transition duration-300 font-medium hover:bg-gray-700"
              >
                Edit Podcast Cover
              </button>
              {!podcastGenerated && (
                <button
                  onClick={generatePodcast}
                  className="py-2 px-4 text-sm text-white bg-blue-500 rounded-lg transition duration-300 font-medium hover:bg-blue-700"

                >
                  {isDataLoading ? (
                    <Radio visible={true} colors={['#ffff', '#ffff', '#ffff']} width="50" height="50" wrapperStyle={{ display: 'inline-block' }} />
                  ) : (

                    'Generate Podcast'
                  )}
                </button>
              )}
              {podcastGenerated && (
                <div className="flex items-center justify-center">
                  <input
                    type="email"
                    placeholder="Enter your email"
                    className="py-2 px-4 text-sm text-gray-700 bg-white border border-gray-300 rounded-l-md transition duration-300 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:border-transparent"
                    style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    id="emailInput"
                  />
                  <button
                    onClick={sharePodcast}
                    className="py-2 px-4 text-sm text-white bg-green-600 border border-green-600 rounded-r-md transition duration-300 font-medium hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    style={{ height: 'fit-content' }}
                  >
                    Share Podcast
                  </button>
                </div>



                // <button
                //   onClick={sharePodcast}
                //   className="py-2 px-4 text-sm text-white bg-green-600 rounded-lg transition duration-300 font-medium hover:bg-green-800"
                // >
                //   Share Podcast
                // </button>
              )}
            </>
          )}
        </div> */}
      </div>
      <PodcastModal generatePodcast={generatePodcast} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />

    </div>
  );
}

export default PodcastCard;
