import React, { useEffect, useState } from "react";
import { useApi } from '../callApi';
import { Helmet } from "react-helmet-async";
import PodcastCard from "../components/PodcastCard";
import Footer from "../components/shared/Footer";

export default function Feed() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [podcasts, setPodcasts] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const { callApi } = useApi(true);

  const getPodcasts = () => {
    setIsDataLoading(true);
    const response = callApi("/podcast/episodes_catalog"); // Adjust this endpoint as needed
    response.then(data => {
      setPodcasts(data);
      setIsDataLoading(false);
    });
  }

  useEffect(() => {
    getPodcasts();
  }, []);

  return (
    <div>
      <div className="relative min-h-screen bg-cover bg-no-repeat bg-center" style={{ backgroundImage: "url('/dashy-assets/images/bg.gif')" }}>
        <div className="absolute inset-0 bg-black opacity-50"></div>

        {/* Semi-transparent header */}
        <header className="relative py-6 md:py-8" style={{ backgroundColor: 'rgba(255, 255, 255, 0.75)' }}>
          <div className="container mx-auto flex flex-wrap items-center justify-between px-4 md:px-6">
            <div className="flex items-center">
              <img width={'60px'} src='/dashy-assets/logos/mic.png' alt='InstaCast Logo' className="h-10 md:h-12 mr-3" />
              <div>
                <h1 className="text-xl md:text-2xl font-semibold text-gray-800">InstaCast</h1>
                <p className="text-gray-500">AI Host to Share Your Story With The World</p>
              </div>
            </div>
            {/* <div>
            <a href="https://twitter.com/messages/compose?recipient_id=3435143933" target="_blank" rel="noopener noreferrer"
              className="inline-block bg-black text-white font-semibold px-6 py-2 rounded-lg shadow-lg hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50 transition ease-in-out duration-150">
              Get Early Access
            </a>
          </div> */}
          </div>
        </header>

        <div className="relative pt-16 md:pt-24">
          <div className="container mx-auto text-center p-8">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {isDataLoading ? (
                <p>Loading podcasts...</p>
              ) : (
                podcasts.map(podcast => (
                  <PodcastCard viewMode={true} key={podcast.episode_id} episodeID={podcast.episode_id} podcast={podcast} />
                ))
              )}
            </div>









          </div>


        </div>

        {/* Podcast Cards Grid */}
        {/* <div className="container mx-auto px-4 md:px-6 py-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          
          </div>
        </div> */}
        <Footer />
      </div>

    </div>
  );
}
