import React, { useState, useEffect, useRef } from 'react';

const EditableText = ({
  tag: Tag = 'span',
  className = '',
  placeholder = 'Write here', // Placeholder text to display
  initialValue = "Click to edit",
  onSave,
  editable = false, // Prop to control edit mode visual cues
  ...props
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(initialValue);
  const inputRef = useRef(null);

  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isEditing]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      setIsEditing(false);
      if (event.key === 'Enter') onSave(value);
      if (event.key === 'Escape') setValue(initialValue); // Revert changes
    }
  };

  const handleBlur = () => {
    setIsEditing(false);
    onSave(value);
    if (value.trim() === '') {
      setValue(initialValue); // Reset to initialValue if empty
    }
  };

  if (isEditing) {
    return (
      <input
        ref={inputRef}
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={(e) => setValue(e.target.value)}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        className={`${className} editable-input`}
      />
    );
  }

  // Display placeholder or value. If value is empty, show a placeholder or "Click to edit".
  const displayValue = value.trim() !== '' ? value : placeholder || 'Click to edit';

  return (
    <Tag
      onClick={() => editable && setIsEditing(true)}
      className={`${className} editable-text`}
      style={editable ? { display: 'inline-block', borderBottom: '1px dashed lightgray', cursor: 'text' } : { cursor: 'pointer' }}
    >
      {/* Wrap the display value in a span to control the underline width */}
      <span style={{ fontSize: 'inherit' }}>&nbsp; {displayValue}</span>
    </Tag>
  );
};

export default EditableText;
