import React, { useEffect, useState, useRef } from "react";
import { useApi } from '../callApi';
import { Radio } from 'react-loader-spinner';
import StudioComponent from "../components/StudioComponent";
import VoicePlayer from "../components/VoicePlayer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faMicrophoneAlt, faSave, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { faXTwitter, faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import PodcastCard from "../components/PodcastCard";
import LandingBenefits from "../components/LandingBenefits";
import LandingHeader from "../components/LandingHeader";

export default function Landing() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const [isDataLoading, setIsDataLoading] = useState(false);
  const { callApi } = useApi(true);
  const [animationStep, setAnimationStep] = useState(0);
  const [podcastSaved, setpodcastSaved] = useState(false);
  const [userInteracted, setUserInteracted] = useState(false);
  const [sessionID, setSessionID] = useState();
  const [podcast, setPodcast] = useState();
  const [podcasts, setPodcasts] = useState([])

  const startPodcast = (podcast) => {
    if (!podcast.id)
      return

    setAnimationStep(1); // Start the animation
    setTimeout(() => setAnimationStep(2), 2000); // Change text after 2 seconds
    setTimeout(() => setAnimationStep(3), 4000); // Change text after another 2 seconds

    setIsDataLoading(true)

    const response = callApi("/podcast/start?podcast_id=" + podcast.id);
    response.then(e => {
      setTimeout(() => setAnimationStep(0), 6000); // Reset or start the podcast after another 2 seconds

      setSessionID(e.session_id)
      podcast.introText = e.text
      setPodcast(podcast)
      setIsDataLoading(false)

    })

  }


  const getPodcasts = () => {

    setIsDataLoading(true)

    const response = callApi("/podcast/list");
    response.then(e => {
      setPodcasts(e)
      setIsDataLoading(false)

    })

  }

  const didInteract = (interacted) => {
    setUserInteracted(interacted)
  }

  useEffect(() => {

    getPodcasts()

  }, []);





  return (
    <div className="relative min-h-screen">
      <div style={{
        backgroundImage: "url('/dashy-assets/images/bg.gif')",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center'
      }} className="absolute inset-0 w-full h-full">
        <div className="absolute inset-0 bg-black opacity-50"></div>
      </div>

      {/* Semi-transparent header */}
      <LandingHeader />

      {!animationStep && (<>

        {!sessionID && <LandingBenefits />}


        {!sessionID && (
          <div className="relative pt-16 md:pt-8">
            <div className="container mx-auto text-center p-8">
              <div className="grid md:grid-cols-2 gap-4">
                {podcasts.map((podcast, index) => (
                  <div class="bg-white p-4 rounded-lg border hover:opacity-80 border-black hover:border-4 hover:border-black-400">
                    <a onClick={() => startPodcast(podcast)} href="#" className="flex flex-col items-center space-y-4">
                      <img src={podcast.icon} alt={podcast.name} className="w-full h-48 object-cover rounded-t-lg" />
                      <div className="flex items-center w-full">
                        <div style={{ flex: 1 }}>
                          <h3 className="text-lg font-semibold">{podcast.name}</h3>
                          <p className="text-xs text-gray-500" style={{}}>Hosted By AI <b>{podcast.hostName}</b></p>
                        </div>
                        <img src={podcast.hostImage} alt="AI Host: Alloy" className="w-10 h-10 rounded-full mr-4" />
                      </div>
                      <p className="text-sm text-gray-600">{podcast.description}</p>
                      <button disabled={isDataLoading} onClick={startPodcast} className="mt-8 py-3 px-4 text-sm text-white bg-black rounded-lg transition duration-300 w-40 h-13 font-medium">

                        <h2 className="text-lg">
                          <FontAwesomeIcon icon={faMicrophoneAlt} />

                          {!isDataLoading ? 'Join Podcast' : ''}</h2>
                        <Radio
                          visible={isDataLoading}
                          colors={['#ffff', '#ffff', '#ffff']}
                          width="50"
                          height="50"
                          wrapperStyle={{ 'display': 'inline-block' }}
                        />
                      </button>
                    </a>
                  </div>

                ))}
              </div>









            </div>


          </div>
        )}

        {/*Mobile View */}
        {
          isMobile && sessionID && (
            <div className="relative pt-0 md:pt-0">
              <div className="container mx-auto text-center p-8">
                <div className="grid md:grid-cols-1 gap-4">

                  <PodcastCard didInteract={userInteracted} episodeID={sessionID} podcast={podcast} />

                  <div className="max-w-md mx-auto text-center ">
                    <StudioComponent podcastType={'solo'} podcast={podcast} sessionID={sessionID} didInteract={didInteract} />

                  </div>



                </div>


              </div>

            </div>
          )
        }

        {/*Desktop View */}
        {
          !isMobile && sessionID && (
            <div className="relative pt-8 md:pt-8">
              <div className="container mx-auto text-center p-8">
                <div className="grid md:grid-cols-2 gap-4">

                  <StudioComponent podcastType={'solo'} podcast={podcast} sessionID={sessionID} didInteract={didInteract} />
                  <PodcastCard didInteract={userInteracted} episodeID={sessionID} podcast={podcast} />

                </div>


              </div>

            </div>
          )
        }


      </>
      )}

      {animationStep > 0 && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <p className="text-white text-2xl">
            {animationStep === 1 && "Preparing studio..."}
            {animationStep === 2 && "Your host is getting setup..."}
            {animationStep === 3 && "Get your mic ready..."}
          </p>
        </div>
      )}



      {!animationStep && (
        <div className="relative pt-16 md:pt-24">

          <footer className="bg-black text-white">
            <div className="container mx-auto px-4 md:px-6 py-4 flex flex-wrap justify-between items-center">
              <div>
                <p className="text-gray-400 text-sm">&copy; {new Date().getFullYear()} InstaCast.</p>
              </div>
              <div className="flex items-center space-x-6">
                <a href="https://medium.com/@ahmedbehairy/can-ai-make-us-all-podcasters-0f274ecfb627" className="text-gray-400 hover:text-gray-200">Made with love in Dubai ❤️</a>
                {/* <a href="#" className="text-gray-400 hover:text-gray-200">Privacy Policy</a>
              <a href="#" className="text-gray-400 hover:text-gray-200">Terms of Service</a> */}
              </div>
              <div className="flex items-center space-x-4">
                <a target="_blank" href="https://x.com/_ahmedbehairy" aria-label="Twitter" className="text-gray-400 hover:text-gray-200">
                  <FontAwesomeIcon icon={faXTwitter} size="lg" />
                </a>
                {/* <a href="https://facebook.com" aria-label="Facebook" className="text-gray-400 hover:text-gray-200">
                  <FontAwesomeIcon icon={faFacebookF} size="lg" />
                </a>
                <a href="https://instagram.com" aria-label="Instagram" className="text-gray-400 hover:text-gray-200">
                  <FontAwesomeIcon icon={faInstagram} size="lg" />
                </a> */}
              </div>
            </div>
          </footer>

        </div>)}

    </div >
  );

}