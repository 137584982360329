const LandingHeader = ({ }) => {
  return (

    // <header className="relative py-6 md:py-8" >
    <header className="relative py-6 md:py-8" style={{ "background-color": "rgba(255, 255, 255, 0.75)" }}   >

      <div className="container mx-auto flex flex-wrap items-center justify-between px-4 md:px-6">
        <div className="flex items-center">
          <img width={'50px'} src='/dashy-assets/logos/mic.png' alt='InstaCast Logo' className="mr-3" />
          <div>
            <h1 className="text-xl md:text-3xl font-bold text-gray-800">InstaCast</h1>
            <p className="text-gray-500">AI Host to Share Your Story With The World</p>
          </div>
        </div>
        {/* <div>
          <a href="https://twitter.com/messages/compose?recipient_id=3435143933" target="_blank" rel="noopener noreferrer"
            className="inline-block bg-black text-white font-semibold px-6 py-2 rounded-lg shadow-lg hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50 transition ease-in-out duration-150">
            Why InstaCast?
          </a>
        </div> */}
      </div>
    </header>)
}
export default LandingHeader