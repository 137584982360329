import { faHeadphones, faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LandingBenefits = ({ }) => {
  return (
    <section className="relative py-16 pb-0">
      <div className="container mx-auto text-center px-4">
        <h1 className="md:text-5xl text-3xl font-bold text-white mb-4">Podcasts for All - No Studio Required</h1>
        <p className="text-xl font-semibold text-gray-300 mb-8">
          Join a Podcast or Start yours in seconds!


        </p>
        <a href="/feed" className="py-2 px-4 text-lg text-white bg-black rounded-lg transition duration-300 font-medium ">

          <FontAwesomeIcon icon={faHeadphones} />

          &nbsp; Listen to stories</a>

        {/* <ul className="text-gray-300 text-lg list-none space-y-4">
          <li>🎙 <span className="underline font-semibold">AI Hosts:</span> ...</li>
          <li>🌍 <span className="underline font-semibold">Reach Globally:</span> ...</li>
          <li>📈 <span className="underline font-semibold">Grow Your Audience:</span> ...</li>
        </ul> */}
      </div>
    </section>
  )
}
export default LandingBenefits  